<messages>["./TransferConfirm"]</messages>
<i18n>
  {
  "fr": {
  "label": {
  "loadingError": "Le transfert n'a pas pu être chargé",
  "transferComplete": "Le transfert est déjà terminé",
  "alreadyDecided": "La décision a déjà été prise",
  "notFound": "Le transfert n'a pas été trouvé. Veuillez vérifier l'URL.",
  "notAuthorized": "Le transfert n'a pas été trouvé. Veuillez vérifier l'URL.",
  "confirmError": "Le transfert n'a pas pu être traité.",
  "success": "Transfert traité avec succès",
  "here": "ici",
  "submit": "Soumettre",
  "heading": "Confirmation du transfert",
  "titleincomingdomain": "Autorisation initiale de transfert de bureau d'enregistrement - Domaine {name}.",
  "titleincomingemail": "Autorisation initiale de transfert de bureau d'enregistrement - Adresse de réexpédition de
  courrier électronique {name}.",
  "titleoutgoingdomain": "Confirmation du transfert de bureau d'enregistrement - Domaine {name}",
  "titleoutgoingemail": "Confirmation du transfert de bureau d'enregistrement - Adresse de réexpédition de courrier
  électronique {name}",
  "salutationlabel": "Attention",
  "salutationunknown": "inconnu",
  "subjectlabel": "Concerne",
  "subjectdomain": "Transfert du nom de domaine",
  "subjectemail": "Transfert de l'adresse de redirection d'e-mail",
  "bodyincomingdomain1": {
  "p1": "Le bureau d'enregistrement actuel pour ce domaine est {0}."
  },
  "bodyincomingdomain2": {
  "p1": "{0} a reçu une demande de {1} le {2} pour que nous devenions le nouveau bureau d'enregistrement",
  "p2": "Vous avez reçu ce message parce que vous êtes inscrit en tant que titulaire du nom de domaine enregistré ou
  contact administratif pour ce nom de domaine dans la base de données WHOIS.",
  "p3": "Veuillez lire les informations importantes suivantes concernant le transfert de votre nom de domaine :"
  },
  "bodyincomingdomain3": {
  "p1": "Vous devez accepter de conclure un nouveau contrat d'enregistrement avec nous. Vous pouvez consulter l'ensemble
  des modalités et conditions de l'Entente {here}.",
  "p2": "Une fois que vous avez conclu l'Entente, le transfert aura lieu dans les cinq (5) jours civils à moins que le
  registraire inscrit actuel ne refuse la demande.",
  "p3": "Une fois le transfert effectué, vous ne pourrez pas le transférer à un autre bureau d'enregistrement avant 60
  jours, sauf s'il s'agit d'un transfert de nouveau au bureau d'enregistrement initial, si les deux bureaux
  d'enregistrement en conviennent ainsi ou si une décision rendue dans le cadre du processus de règlement des différends
  le prévoit."
  },
  "bodyincomingdomain4": {
  "p1": "Si vous {emp} avec le transfert, vous devez répondre à ce message (notez que si vous ne répondez pas par {0},
  {emp2} ne nous sera pas transféré)",
  "p2": "Si vous {emp} le transfert pour continuer, alors ne répondez pas à ce message.",
  "p3": "Si vous avez des questions concernant ce processus, veuillez contacter l'entreprise où vous avez enregistré
  votre domaine."
  },
  "bodyincomingemail1": {
  "p1": "Le bureau d'enregistrement actuel pour cette adresse de redirection de courriel est {0}."
  },
  "bodyincomingemail2": {
  "p1": "{0} a reçu une demande de {1} le {2} pour que nous devenions le nouveau bureau d'enregistrement",
  "p2": "Vous avez reçu ce message parce que vous êtes inscrit comme titulaire du nom de domaine enregistré ou contact
  administratif pour cette adresse électronique dans la base de données WHOIS.",
  "p3": "Veuillez lire les informations importantes suivantes sur le transfert de votre adresse de redirection e-mail:"
  },
  "bodyincomingemail3": {
  "p1": "Vous devez accepter de conclure un nouveau contrat d'enregistrement avec nous. Vous pouvez consulter l'ensemble
  des modalités et conditions de l'Entente {here}.",
  "p2": "Une fois que vous avez conclu l'Entente, le transfert aura lieu dans les cinq (5) jours civils à moins que le
  registraire inscrit actuel ne refuse la demande.",
  "p3": "Une fois le transfert effectué, vous ne pourrez pas le transférer à un autre bureau d'enregistrement avant 60
  jours, sauf s'il s'agit d'un transfert de nouveau au bureau d'enregistrement initial, si les deux bureaux
  d'enregistrement en conviennent ainsi ou si une décision rendue dans le cadre du processus de règlement des différends
  le prévoit."
  },
  "bodyincomingemail4": {
  "p1": "Si vous {emp} avec le transfert, vous devez répondre à ce message (notez que si vous ne répondez pas par {0},
  {emp2} ne nous sera pas transféré)",
  "p2": "Si vous {emp} le transfert pour continuer, alors ne répondez pas à ce message.",
  "p3": "Si vous avez des questions concernant ce processus, veuillez contacter l'entreprise où vous avez enregistré
  votre adresse e-mail."
  },
  "bodyoutgoingdomain": {
  "p1": "{0} a reçu une notification {1} l'informant que vous avez demandé un transfert vers un autre bureau
  d'enregistrement de nom de domaine. Si vous souhaitez procéder à ce transfert, vous n'avez pas besoin de répondre à ce
  message. Si vous souhaitez annuler le transfert, veuillez sélectionner \"Rejeter le transfert\" ci-dessous.",
  "p2": "Si nous n'avons pas de nouvelles de vous par {0}, le transfert se poursuivra."
  },
  "bodyoutgoingemail": {
  "p1": "{0} a reçu une notification {1} l'informant que vous avez demandé un transfert vers un autre bureau
  d'enregistrement de nom de domaine. Si vous souhaitez procéder à ce transfert, vous n'avez pas besoin de répondre à ce
  message. Si vous souhaitez annuler le transfert, veuillez sélectionner \"Rejeter le transfert\" ci-dessous.",
  "p2": "Si nous n'avons pas de nouvelles de vous par {0}, le transfert se poursuivra."
  },
  "wish": "VOLONTÉ DE PROCÉDER",
  "noWish": "NE VEUILLEZ PAS",
  "approve": "Approuver le transfert",
  "reject": "Rejeter le transfert"
  }
  },
  "nl": {
  "label": {
  "loadingError": "Overdracht kon niet worden geladen",
  "transferComplete": "De overdracht is al voltooid",
  "alreadyDecided": "Er is al een beslissing genomen",
  "notFound": "Overdracht niet gevonden. Controleer de URL.",
  "notAuthorized": "Overdracht niet gevonden. Controleer de URL.",
  "confirmError": "Overdracht kon niet worden verwerkt.",
  "success": "Overdracht met succes verwerkt",
  "here": "hier",
  "heading": "Bevestiging overdracht",
  "titleincomingdomain": "Eerste autorisatie voor registraroverdracht - Domein {name}",
  "titleincomingemail": "Eerste autorisatie voor registraroverdracht - E-Mail doorstuuradres {name}",
  "titleoutgoingdomain": "Bevestiging van registraroverdracht - domein {name}",
  "titleoutgoingemail": "Bevestiging van registraroverdracht - doorstuuradres E-Mail {name}",
  "salutationlabel": "Attentie",
  "salutationunknown": "onbekend",
  "subjectlabel": "Betreft",
  "subjectdomain": "Overdracht van domein",
  "subjectemail": "Overdracht van e-mail doorstuuradres",
  "submit": "Voorleggen",
  "bodyincomingdomain1": {
  "p1": "De huidige registrar van record voor dit domein is {0}."
  },
  "bodyincomingdomain2": {
  "p1": "{0} heeft van {1} op {2} een verzoek ontvangen om de nieuwe registrar van record te worden.",
  "p2": "U hebt dit bericht ontvangen omdat u in de WHOIS-database als geregistreerde houder of administratief
  contactpersoon voor deze domeinnaam wordt vermeld.",
  "p3": "Lees de volgende belangrijke informatie over het verhuizen van uw domeinnaam:"
  },
  "bodyincomingdomain3": {
  "p1": "U moet akkoord gaan om een nieuwe registratieovereenkomst met ons aan te gaan. U kunt de volledige voorwaarden
  van de Overeenkomst {here} lezen",
  "p2": "Nadat u de Overeenkomst bent aangegaan, vindt de overdracht binnen vijf (5) kalenderdagen plaats, tenzij de
  huidige registrar het record weigert.",
  "p3": "Zodra een overdracht heeft plaatsgevonden, kunt u 60 dagen lang niet overstappen naar een andere registrar,
  behalve een overdracht terug naar de oorspronkelijke registrar, in gevallen waarin beide registrars hiermee instemmen
  of een beslissing in het geschillenbeslechtingsproces daartoe aanleiding geeft."
  },
  "bodyincomingdomain4": {
  "p1": "Als u {emp} met de overdracht, moet u op dit bericht reageren (let op: als u niet vóór {0} reageert, wordt
  {emp2} niet aan ons overgedragen).",
  "p2": "Als u {emp} de overdracht doorgaat, reageer dan niet op dit bericht.",
  "p3": "Neem bij vragen over dit proces contact op met het bedrijf waar u uw domein hebt geregistreerd."
  },
  "bodyincomingemail1": {
  "p1": "De huidige registrar van record voor dit e-mail doorstuuradres is {0}."
  },
  "bodyincomingemail2": {
  "p1": "{0} heeft van {1} op {2} een verzoek ontvangen om de nieuwe registrar van record te worden.",
  "p2": "You have received this message because you are listed as the Registered Name Holder or Administrative Contact
  for this e-mail forwarding addressin the WHOIS database.U hebt dit bericht ontvangen omdat u als geregistreerde houder
  of administratief contactpersoon voor dit e-mail doorstuuradres in de WHOIS-database bent vermeld.",
  "p3": "Lees de volgende belangrijke informatie over het overbrengen van uw e-mail doorstuuradres:"
  },
  "bodyincomingemail3": {
  "p1": "U moet akkoord gaan om een nieuwe registratieovereenkomst met ons aan te gaan. U kunt de volledige voorwaarden
  van de Overeenkomst {here} lezen",
  "p2": "Nadat u de Overeenkomst bent aangegaan, vindt de overdracht binnen vijf (5) kalenderdagen plaats, tenzij de
  huidige registrar het record weigert.",
  "p3": "Zodra een overdracht heeft plaatsgevonden, kunt u 60 dagen lang niet overstappen naar een andere registrar,
  behalve een overdracht terug naar de oorspronkelijke registrar, in gevallen waarin beide registrars hiermee instemmen
  of een beslissing in het geschillenbeslechtingsproces daartoe aanleiding geeft."
  },
  "bodyincomingemail4": {
  "p1": "Als u {emp} met de overdracht, moet u op dit bericht reageren (let op: als u niet vóór {0} reageert, wordt
  {emp2} niet aan ons overgedragen).",
  "p2": "Als u {emp} de overdracht doorgaat, reageer dan niet op dit bericht.",
  "p3": "Als u vragen hebt over dit proces, neemt u contact op met het bedrijf waar u uw e-mail doorstuuradres hebt
  geregistreerd."
  },
  "bodyoutgoingdomain": {
  "p1": "{0} heeft een melding ontvangen op {1} dat u een overdracht heeft aangevraagd naar een andere
  domeinnaamregistrar. Als u wilt doorgaan met deze overdracht, hoeft u niet op dit bericht te reageren. Als u de
  overdracht wilt annuleren, selecteert u hieronder \"De overdracht weigeren\".",
  "p2": "Als we uiterlijk {0} niets van u horen, gaat de overdracht door."
  },
  "bodyoutgoingemail": {
  "p1": "{0} heeft een melding ontvangen op {1} dat u een overdracht heeft aangevraagd naar een andere
  domeinnaamregistrar. Als u wilt doorgaan met deze overdracht, hoeft u niet op dit bericht te reageren. Als u de
  overdracht wilt annuleren, selecteert u hieronder \"De overdracht weigeren\".",
  "p2": "Als we uiterlijk {0} niets van u horen, gaat de overdracht door."
  },
  "wish": "WENS VERDER TE GAAN",
  "noWish": "WIL NIET",
  "approve": "De overdracht goedkeuren",
  "reject": "Weiger de overdracht"
  }
  }
  }
</i18n>

<template>
  <v-app>
    <v-container>
      <v-row
        class="fill-height"
        align="center"
        justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-slide-y-transition mode="out-in">
            <v-alert
              v-if="success"
              key="success"
              v-t="'label.success'"
              type="success"/>
            <v-card v-else-if="!isLoading && transfer" key="content">
              <v-card-title>
                <v-col>
                  <h1 class="text-center">
                    {{ title }}
                  </h1>
                </v-col>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-row
                      class="pt-4"
                      justify="space-around">
                      <v-col class="font-weight-bold" cols="12" md="3">
                        {{ $t(`label.salutationlabel`) }}:
                      </v-col>
                      <v-col
                        class="align-content-start"
                        cols="12" md="9">
                        {{ transfer.name && transfer.name !== '' ? transfer.name : $t('label.salutationunknown') }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row
                      class="pt-4"
                      justify="space-around">
                      <v-col class="font-weight-bold" cols="12" md="3">
                        {{ $t(`label.subjectlabel`) }}:
                      </v-col>
                      <v-col
                        class="align-content-start"
                        cols="12" md="9">
                        {{ $t (`label.subject${transfer.type}`) }}
                        <span class="font-weight-bold">{{ transfer.object }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="transfer.direction === 'incoming'" cols="12">
                    <div v-if="transfer.losingRegistrar && transfer.losingRegistrar.trim ().length > 0" class="par">
                      {{ $t (`label.bodyincoming${transfer.type}1.p1`, [transfer.losingRegistrar]) }}
                    </div>

                    <div class="par">
                      {{ $t (`label.bodyincoming${transfer.type}2.p1`,
                             [
                               transfer.gainingRegistrar,
                               transfer.maintainer,
                               formatDate (transfer.requestDate)
                             ]
                      ) }}
                    </div>

                    <div
                      v-t="`label.bodyincoming${transfer.type}2.p2`"
                      class="par"/>

                    <div
                      v-t="`label.bodyincoming${transfer.type}2.p3`"
                      class="par"/>

                    <ul>
                      <li>
                        <div class="par">
                          <i18n :path="`label.bodyincoming${transfer.type}3.p1`">
                            <template #here>
                              <agreement-dialog :title="$t('label.here')" :client-name="transfer.clientName"/>
                            </template>
                          </i18n>
                        </div>
                      </li>
                      <li>
                        <div
                          v-t="`label.bodyincoming${transfer.type}3.p2`"
                          class="par"/>
                      </li>
                      <li>
                        <div
                          v-t="`label.bodyincoming${transfer.type}3.p3`"
                          class="par"/>
                      </li>
                    </ul>

                    <div class="par">
                      <i18n
                        :path="`label.bodyincoming${transfer.type}4.p1`"
                        :places="[formatDate (transfer.dueDate), transfer.name]">
                        <template #emp>
                          <span
                            v-t="`label.wish`"
                            class="font-weight-bold"/>
                        </template>

                        <template #emp2>
                          <span
                            class="font-weight-bold"
                            v-text="transfer.object"/>
                        </template>
                      </i18n>
                    </div>

                    <div class="par">
                      <i18n :path="`label.bodyincoming${transfer.type}4.p2`">
                        <template #emp>
                          <span
                            v-t="`label.noWish`"
                            class="font-weight-bold"/>
                        </template>
                      </i18n>
                    </div>

                    <div
                      v-t="`label.bodyincoming${transfer.type}4.p3`"
                      class="par"/>
                  </v-col>
                  <v-col v-else>
                    <div class="par">
                      {{ $t (`label.bodyoutgoing${transfer.type}.p1`,
                             [
                               transfer.gainingRegistrar,
                               formatDate (transfer.requestDate)
                             ]) }}
                    </div>
                    <div class="par">
                      {{ $t (`label.bodyoutgoing${transfer.type}.p2`,
                             [
                               formatDate (transfer.dueDate)
                             ]) }}
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-radio-group v-model="decision" row>
                      <v-radio
                        :label="$t ('label.reject')"
                        color="error"
                        value="reject"/>
                      <v-radio
                        :label="$t ('label.approve')"
                        color="success"
                        value="approve"/>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn
                  v-t="'label.submit'" color="primary" :loading="isLoadingConfirm"
                  @click="confirm"/>
              </v-card-actions>
            </v-card>
            <v-alert
              v-else-if="!isLoading"
              key="error"
              type="error">
              {{ error && error !== '' ? $t (error) : $t ('label.loadingError') }}
            </v-alert>
            <v-progress-linear
              v-else
              key="loading"
              indeterminate
              color="primary"/>
          </v-slide-y-transition>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'

  import AgreementDialog from './AgreementDialog'

  export default {

    components: {
      AgreementDialog
    },
    props: {
      lang: {
        type: String,
        required: true
      },
      transferId: {
        type: Number,
        required: true
      },
      role: {
        type: Number,
        required: true
      },
      secret: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        isLoading: true,
        isLoadingConfirm: false,
        transfer: null,
        decision: 'reject',
        error: '',
        memberId: null,
        success: false
      }
    },

    computed: {
      title () {
        return this.$t (
          `label.title${this.transfer.direction}${this.transfer.type}`,
          {name: this.transfer.object})
      }
    },

    watch: {
      lang () {
        this.setLanguage (this.lang)
      },

      transferId () {
        this.loadTransfer ()
      },

      role () {
        this.loadTransfer ()
      },
      secret () {
        this.loadTransfer ()
      }
    },

    created () {
      this.setLanguage (this.lang)
      this.loadTransfer ()
      document.title = this.$t ('label.heading')
    },

    methods: {
      ...mapMutations ('locale', [
        'setAppLocale'
      ]),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadTransfer () {
        this.error = null
        this.isLoading = true
        this.fetchData ({
          op: 'transfer/show',
          params: {
            role: this.role,
            id: this.transferId,
            signature: this.secret
          },
          cb: data => {
            this.transfer = data.data
          },
          cbError: this.displayError,
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      confirm () {
        this.error = null
        this.isLoadingConfirm = true
        this.fetchData ({
          op: 'transfer/confirm',
          params: {
            role: this.role,
            id: this.transferId,
            signature: this.secret,
            decision: this.decision
          },
          cb: () => {
            this.setSuccess ()
          },
          cbError: this.displayError,
          cbFinal: () => {
            this.isLoadingConfirm = false
          }
        })
      },

      setLanguage (lang) {
        // const l = locales.includes (lang) ? lang : defaultLocale
        this.$i18n.locale = lang
        this.setAppLocale (lang)
      },

      displayError (data) {
        const code = data.result[0].code
        const params = data.result[0].params
        let error

        switch (code) {
          case 'error/security/authorization':
            error = 'label.notAuthorized'
            break
          case 'error/datamgmt/objdoesnotexist':
            error = 'label.notFound'
            break
          case 'error/server/failure':
            error = 'label.confirmError'
            break
          case 'error/syntax/extravalue':
            if (params.property === 'status') {
              error = 'label.transferComplete'
            } else {
              error = 'label.alreadyDecided'
            }
            break
          default:
            error = 'label.loadingError'
        }

        this.error = error
        this.transfer = null
      },

      setSuccess () {
        this.success = true
      }
    }
  }
</script>

<style scoped>
  .par {
    margin-top: 9pt;
    margin-bottom: 9pt;
    line-height: 1.3em;
  }
</style>
