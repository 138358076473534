<messages>["./TransferConfirm"]</messages>
<template>
  <base-dialog
    v-model="dialog"
    close-on-esc
    mw0
    scrollable>
    <template #activator="{ on }">
      <a style="text-decoration: underline" v-on="on">
        {{ title }}
      </a>
    </template>

    <v-card>
      <v-card-text style="height: 600px;">
        <agreement :client-name="clientName"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-t="'general.button.close'" text
          @click="dialog = false"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
  import Agreement from './TransferAgreement.vue'
  import BaseDialog from '@/app/core/components/BaseDialog'

  export default {
    components: {
      Agreement,
      BaseDialog
    },

    props: {
      clientName: {
        type: String,
        default: '[Name of Client]'
      },
      title: {
        type: String,
        default: 'here'
      }
    },

    data () {
      return {
        dialog: false
      }
    }
  }
</script>
