<messages>["./TransferConfirm"]</messages>
<template>
  <div class="contents">
    <div align="center">
      <h2> DOMAIN NAME REGISTRATION AGREEMENT</h2>

      <h3>General Terms and Conditions</h3>

      <h3>(Version 6 - effective September 21st, 2022)</h3>
    </div>
    <p>&nbsp;</p>
    <p>To complete the registration process you must read and agree to be bound by all the following terms and conditions.</p>

    <h3>1. NOTICES FROM ICANN</h3>
    <ul>
      <li>
        <p>
          ICANN has published an educational webpage summarizing the terms of the Registrar Accreditation Agreement and related Consensus Policies, currently located at ICANN's website <a href="http://www.icann.org/en/registrars/registrant-rights-responsibilities-en.htm">
            http://www.icann.org/en/registrars/registrant-rights-responsibilities-en.htm
          </a>
        </p>
      </li>
      <li>
        <p>
          Also, you can find Registrants' Benefits and Responsibilities at <a href="http://whois.icann.org/en/2013-raa-registrant-benefits-and-responsibilities">
            http://whois.icann.org/en/2013-raa-registrant-benefits-and-responsibilities
          </a>
        </p>
      </li>
    </ul>

    <h3>2. ACCEPTANCE</h3>
    <p>a. This is an Agreement by and between You and COREhub. This Agreement explains Our obligations to You and Your obligations to Us for the provision of the registration services offered by Us through the Reseller. In order to complete the registration process you must accept all terms and conditions of this Agreement. </p>
    <p>b. You acknowledge and understand that by accepting this Agreement You also accept to be bound and comply with: </p>
    <ol type="i">
      <li value="1">
        The Registry Policies, as amended from time to time, set forth by the Registry Operator in charge of the TLDs you are registering. &quot;<b>Registry Policies</b>&quot; refers to the terms and conditions in the initial launch and General Registration Period of the relevant Registry, including without limitation any standards, policies, specifications, procedures, guidelines, practices, programs or criteria approved by the relevant Registry in accordance with their arrangement with ICANN, now or in the future. <b>Appendix 1</b> of this Registration Agreement refers to these Registry Policies. You are aware that Registries may update the content and/or URL for their Registry Policies and are responsible for monitoring them on a regular basis; and
      </li>

      <li value="2">
        All Public Interest Commitments as set forth in Specification 11 to the relevant TLD Registry Agreement, as amended from time to time (all TLD Registry Agreements are available at <a href="https://www.icann.org/resources/pages/registries/registries-agreements-en">
          https://www.icann.org/resources/pages/registries/registries-agreements-en
        </a>); and
      </li>

      <li value="3">
        All community registration policies as set forth in Specification 12 to the relevant TLD Registry Agreement, as amended from time-to-time (all TLD Registry Agreements are available at <a href="https://www.icann.org/resources/pages/registries/registries-agreements-en">
          https://www.icann.org/resources/pages/registries/registries-agreements-en
        </a>).
      </li>
    </ol>
    <p>c. You must comply with all applicable laws, including those that relate to privacy, data collection, consumer protection (including in relation to misleading and deceptive conduct), fair lending, debt collection, organic farming, disclosure of data, and financial disclosures.</p>
    <p>d. This Agreement shall not be effective and binding to both parties until and if accepted by Us. The registration of the domain name shall imply Our acceptance of the Agreement.</p>

    <h3>3. DEFINITIONS</h3>
    <p>In this Registration Agreement (&quot;<b>Agreement</b>&quot;):</p>
    <p>
      a. &quot;<b>Registrant</b>&quot;, "<b>You</b>" and "<b>Your</b>" refers to the individual or entity who is entering into this Agreement and wishes to register a domain name using the registration services provided by COREhub as offered through &quot;<span class="emp">
        {{ memberName }}
      </span>&quot;.
    </p>
    <p>
      b. &quot;<b>COREhub</b>&quot;, &quot;<b>We</b>&quot;, &quot;<b>Us</b>&quot; and &quot;<b>Our</b>&quot; refers to COREhub S.R.L.U, with domicile in C/ Gran Via de Les Corts Catalanes 452 – Atic-2a, 08015 Barcelona, a registrar accredited by ICANN to provide registration services through &quot;<span class="emp">
        {{ memberName }}
      </span>&quot;.
    </p>
    <p>c. &quot;<b>Supported TLDs</b>&quot; refers to the Top-Level Domains with the suffixes referred to in <b>Appendix 1</b>.</p>
    <p>
      d. &quot;<span class="emp">
        {{ memberName }}
      </span>&quot; refers to the COREhub reseller through which your application is processed (&quot;<b>Reseller</b>&quot;).
    </p>
    <p>e. Any reference to a &quot;<b>Registry</b>&quot; or &quot;<b>Registry Operator</b>&quot; shall refer to the entity responsible for operating the registry for the top-level domain (&quot;<b>TLD</b>&quot;) where the domain name You apply for is to be registered.</p>

    <h3>4. MODIFICATIONS TO THIS AGREEMENT</h3>
    <p>a. You acknowledge that the practice of registering and administering domain names is constantly evolving. Therefore, You agree that We may need to modify this Agreement, as is necessary to comply with the requirements of ICANN and/or the Registries. We may, in Our sole discretion, elect to discontinue offering registrations or renewals of some or all TLDs by providing a previous reasonable notice, in which case You would be offered the opportunity that your domain name registration is transferred to another registrar. </p>
    <p>b. All amendments to this Agreement will be posted on COREhub website at http://corehub.net/registrationagreement/.</p>
    <p>c. Registry Policies referred to in <b>Appendix 1</b> of this Agreement, as well as the URL where they are currently located, may also be modified by the relevant Registry. You are responsible for monitoring such Policies in the relevant Registry's site on a regular basis.</p>
    <p>d. Your continued use of the domain name registered through Our Registration Services will constitute Your acceptance of this Agreement and of the applicable Registry Policies and any revisions. If You do not agree to any change, You may request that your domain name registration is cancelled or transferred to a different accredited registrar. You agree that such cancellation or request for transfer will be Your exclusive remedy if You do not wish to abide by any change to this Agreement or to the applicable Registry Policies.</p>

    <h3>5. MANAGING A DOMAIN NAME</h3>
    <p>a. By applying to register a domain name, or by asking Us to maintain or renew a domain name registration, You agree that neither the registration of the domain name nor the manner in which it is intended to be used infringes (i) this Agreement; (ii) applicable Registry Policies; (iii) any applicable Law; or (iv) otherwise violates any third-party rights.</p>
    <p>b. You agree and acknowledge that it is Your responsibility to determine whether Your domain name registration and use infringe the Law or violates someone else's rights, including, but not limited to, whether any foreign language translations of your domain name infringe or violate someone else's rights.</p>
    <p>c. You agree that the domain name shall be in accordance with the syntax norms established by ICANN and/or the relevant Registry, and shall not consist of any of the reserved names established by the Registry Operator. You acknowledge that a domain name registered against the syntax norms, the reserved names, or the Registry Policies may be cancelled without a refund. We will not be liable in any case for such cancellation.</p>
    <p>d. We cannot guarantee that You will obtain a desired domain name, even if an inquiry indicates that a domain name is available at the time of Your application. You agree that in the event of any dispute concerning the time of the entry of a domain name registration into the Registry system, the timestamp shown in the Registry system records shall control.</p>
    <p>e. You acknowledge and agree that registration of Your chosen domain name does not confer immunity from objection to the registration or use of the domain name.</p>

    <h3>6. ADDITIONAL PUBLIC INTEREST COMMITMENTS</h3>
    <p>a. If You collect and maintain sensitive health and financial data You agree to implement reasonable and appropriate security measures commensurate with the offering of those services, as defined by applicable law.</p>
    <p>
      b. With respect to TLD strings associated to highly-regulated sectors with closed entry requirements in multiple jurisdictions as defined in <a href="https://www.icann.org/en/system/files/files/resolutions-new-gtld-annex-2-05feb14-en.pdf">
        ANNEX 2 of ICANN NGPC Resolution No. 2014.02.05.NG01
      </a>, including health and fitness (.pharmacy, .surgery, .dentist , .dds, .hospital, .medical, .doctor), financial (.bank, .banque, .creditunion, .creditcard, .insurance, .ira, .lifeinsurance, .mutualfunds, .mutuelle, .vermogensberater, and .vesicherung, .autoinsurance, .carinsurance) gambling (.bet, .bingo, .lotto, .poker,.spreadbetting, .casino), charity (.charity and IDN Chinese equivalent) education (.university), professional services (.abogado, .attorney, .cpa, .dentist, .dds, .lawyer, .doctor), and corporate identifiers (.corp, .gmbh, .inc, .llc, .llp, .ltda, .ltd, .sarl, .srl, .sal), you additionally represent and agree to the following:
    </p>
    <ol type="i">
      <li value="1">
        You represent that You possess any necessary authorizations, charters, licenses and/or other related credentials for participation in the sector associated with the Registry TLD string of Your chosen domain name/s.
      </li>

      <li value="2">
        You agree to report any material changes to the validity of Your authorizations, charters, licenses and/or other related credentials for participation in the sector associated with the TLD string of Your domain name/s in order to ensure You continue to conform to appropriate regulations and licensing requirements and generally conduct Your activities in the interests of the consumers You serve.
      </li>
    </ol>

    <h3>7. FEES</h3>
    <p>As consideration for the registration services regulated in this Agreement You agree to pay Reseller the applicable fees prior to the desired domain registration, transfer, or any renewal thereof. Fees are non-refundable even if Your domain name is suspended, cancelled or transferred to another registrar prior to the end of Your current registration term. These fees have been set by the Reseller. Thus, any question in relation to any of these fees, must be addressed to the Reseller directly.</p>

    <h3>8. PERSONAL DATA PROTECTION POLICY</h3>
    <p>
      This Clause consists of a summary of the basic terms of Our Data Protection Policy. You can find more detailed information in the &quot;Extended Information About Personal Data Protection Policy&quot; available here (<a href="https://corehub.net/privacy">
        https://corehub.net/privacy
      </a>), hereby incorporated by reference.
    </p>
    <p>
      a. <b>Controller:</b> COREhub, S.R.L.U. You can contact Us at <a href="mailto:legal@corehub.net">
        legal@corehub.net
      </a>. Complete contact information: <a href="https://corehub.net/about/">
        https://corehub.net/about/
      </a>
    </p>
    <p>b. <b>Purpose:</b> We only collect and process data which is strictly necessary to provide You with the domain name registration services requested by You to the Reseller (as the Reseller is using Our Registrar accreditation to register Your domain name).</p>
    <p>c. <b>Data Processed:</b> We only collect and process the following data during the domain name registration with respect to each domain name We sponsor (jointly, the &quot;<b>Data Processed</b>&quot;):</p>
    <ul>
      <li>Domain name</li>
      <li>Domain name category and launch phase (when applicable)</li>
      <li>Registrant's full name, postal address, e-mail address, voice telephone number, and (where available) fax number</li>
      <li>Technical contact's full name, postal address, e-mail address, voice telephone number, and (where available) fax number</li>
      <li>Administrative contact's full name, postal address, e-mail address, voice telephone number, and (where available) fax number</li>
      <li>Billing contact's full name, postal address, e-mail address, voice telephone number, and (where available) fax number</li>
      <li>Any other data element required by ICANN and/or the relevant Registry Operator (for example, some Registry Operators require a declaration of &quot;Intended Use&quot; or further evidence to demonstrate compliance with specific eligibility requirements).</li>
      <li>Service-related data that is not personal in nature (name of the Reseller providing the service; domain name registration date and expiration; domain status; name servers associated to the domain name; DNSSEC service request, as the case may be). </li>
    </ul>
    <p>d. <b>Legal Basis for Processing:</b> Processing is strictly necessary for the performance of this Registration Agreement.</p>
    <p>e. <b>Recipients:</b></p>
    <ul>
      <li>
        <b>Registry Operators:</b> To provide You with the domain name registration services requested, We must transfer some or all of the Data Processed to the relevant Registry. A list of the Registry Operators operating each supported TLD, and their respective Policies, is available at <a href="https://corehub.net/services/supported-tlds/">
          https://corehub.net/services/supported-tlds/
        </a>.
      </li>
      <li>
        <b>ICANN:</b> Upon ICANN's request, we must share the data with ICANN for inspection, as required in Our agreement with ICANN available at <a href="https://www.icann.org/resources/pages/approved-with-specs-2013-09-17-en">
          https://www.icann.org/resources/pages/approved-with-specs-2013-09-17-en
        </a>.
      </li>
      <li><b>Processors:</b> Processing will be carried out by third party processors (such as the Escrow agent and other technical vendors), all of which have provided sufficient technical and organizational guarantees in full compliance with the EU General Data Protection Regulation and the applicable national laws. </li>
    </ul>
    <p>
      More information about data recipients and other third party processors can be found in the Extended Personal Data Protection Policy available at <a href="https://corehub.net/privacy/extended/">
        https://corehub.net/privacy/extended/
      </a>
    </p>
    <p>
      f. <b>Public Whois:</b> Some Data Processed is published in the public &quot;Whois&quot; database service, where the information may be consulted, on a query-by-query basis, by anybody with access to the Internet. This publication is done only to the extent strictly necessary to provide You with the domain name registration services requested, as detailed below in the &quot;Extended Information About Personal Data Protection Policy&quot; Policy available at <a href="https://corehub.net/privacy/extended/">
        https://corehub.net/privacy/extended/
      </a>.
    </p>
    <p>g. <b>Source:</b> The Data Processed have been obtained from the Reseller to which You have requested the domain name registration. The said Reseller will be a joint controller of the Data Processed and a sole controller for any other personal information which you may have provided to them in addition to the Data Processed. </p>
    <p>
      h. <b>Retention</b>: The Data Processed will be deleted one (1) year after the termination of the Registration Agreement. Specifically, We completely erase contact handles from the production system when they are not linked to an active domain for longer than one (1) year. We keep historical data in a secure, separate and access-restricted database for a longer period to comply with mandatory statutory provisions (tax, accounting, legal claims. etc.), as detailed in the &quot;Extended Information About Personal Data Protection Policy&quot; available at <a href="https://corehub.net/privacy/extended/">
        https://corehub.net/privacy/extended/
      </a>.
    </p>
    <p>
      i. <b>Rights:</b> You've got lots of rights, including the right of access, the right (and duty of) rectification, the right to request the erasure and restriction of the processing, the right to object to the processing as well as the right to data portability. Be aware, though, that in some cases the exercise of these rights may make Us unable to continue providing you with the domain name registration service. You also have the right to complain to the Information Commissioner's Office and to the Data Protection Agencies. If you need a hand in exercising your rights, feel free to contact us at <a href="mailto:legal@corehub.net">
        legal@corehub.net
      </a>.
    </p>

    <h3>9. ACCURACY</h3>
    <p>a. You agree to provide all the Data Processed required for the domain name registration, which must be complete, accurate and reliable. During the term of the domain name registration, You shall immediately correct and update the Data Processed. </p>
    <p>b. You acknowledge that a breach of this Section will constitute a material breach of this Agreement, which will entitle us, the Reseller and the relevant Registry Operator, at our sole discretion, to immediately terminate this Agreement and/or temporarily suspend Your domain name without any refund.</p>

    <h3>10. RENEWAL</h3>
    <p>a. You are solely responsible for ensuring the domain name is renewed. If You fail to renew Your domain name in a timely fashion, Your Registration will expire, Your rights to such domain name will terminate and the domain will be disabled so it no longer resolves on the Internet.</p>
    <p>b. Once your registration has expired, the Reseller will only permit You to redeem a deleted registration during the Redemption Grace Period if such Redemption Grace Period is offered by the respective Registry. Additional costs for the redemption and re-registration will apply, in accordance with the price list published in the Reseller's website.</p>
    <p>c. We and the Reseller shall have no liability to You or any third party in connection with the renewal or any attempt to renew the domain name as described herein, including but not limited to any failure or errors in renewing, or attempting to renew the domain name. This limitation of liability is in addition to any other limitations of liability set forth in this Agreement.</p>

    <h3>11. CHANGE OF REGISTRANT</h3>
    <p>
      a. You expressly designate &quot;<span class="emp">
        {{ memberName }}
      </span>&quot; as your Designated Agent, thus explicitly authorizing &quot;<span class="emp">
        {{ memberName }}
      </span>&quot; to approve any change of registrant which is requested to COREhub on your behalf. Any revocation of this designation must be sent in writing to <a href="mailto:support@corehub.net">
        support@corehub.net
      </a> and may automatically lead to the termination of this Agreement.
    </p>
    <p>b. You explicitly opt out of the 60-day inter-registrar transfer lock following to any change of registrant request which is sent to COREhub on your behalf.</p>

    <h3>12. POLICY OF USE</h3>
    <p>a. In this Agreement by &quot;use,&quot; &quot;usage&quot; or &quot;using&quot; Your domain name, we mean any use involving the Internet, including, but not limited to, website(s) and/or any pages thereof resolving at Your domain, either directly or indirectly (including redirection, framing, pop-up windows/browsers, linking, etc.) and email distribution and/or reception.</p>
    <p>b. You agree that the domain name registered through COREhub will be used in compliance with (i) this Agreement, as amended from time to time (ii) any applicable Registry Policies; (iii) applicable statute, rule or law governing use of the Internet and/or electronic commerce (specifically including &quot;phishing,&quot; &quot;pharming,&quot; and/or distributing Internet viruses and other destructive activities).</p>
    <p>c. You must abstain from distributing malware, abusively operating botnets, phishing, piracy, trademark or copyright infringement, fraudulent or deceptive practices, counterfeiting or otherwise engaging in activity contrary to applicable law. </p>
    <p>d. You will take reasonable steps to avoid misrepresenting or falsely implying that You or Your business is affiliated with, sponsored or endorsed by one or more country's or government's military forces if such affiliation, sponsorship or endorsement does not exist.</p>
    <p>e. You are in all cases responsible for providing and updating Your full Contact Information, including accurate technical and administrative Contact Information, which must be adequate to facilitate timely resolution of any problems that arise in connection with Your Registered Name, even if You intend to license the use of Your Registered Name to a third party.  You accept liability for harm caused by wrongful use of the Registered Name unless You disclose the current contact information provided by the licensee and the identity of the licensee within seven (7) days to a party providing You reasonable evidence of actionable harm.</p>
    <p>f. You may not use Your domain for the following types of activity:</p>
    <ol type="i">
      <li value="1">
        Violating the privacy or publicity rights of another member of the global financial services community or any other person or entity, or breaching any duty of confidentiality that You owe to any third party;
      </li>

      <li value="2">
        Promoting or engaging in hate speech, hate crime, terrorism, violence against people, animals, or property, or intolerance of or against any protected class;
      </li>

      <li value="3">
        Promoting or engaging in defamatory, harassing, abusive or otherwise objectionable behavior;
      </li>

      <li value="4">
        Engaging in behavior that is anti-competitive boycotts or otherwise violates anti-trust laws;
      </li>

      <li value="5">
        Promoting or engaging in any spam or other unsolicited bulk email, or computer or network hacking or cracking;
      </li>

      <li value="6">
        Promoting or engaging in any money laundering or terrorist financing activity;
      </li>

      <li value="7">
        Infringing on the intellectual property rights of third parties;
      </li>

      <li value="8">
        Engaging in activities designed to impersonate any third party or create a likelihood of confusion in sponsorship;
      </li>

      <li value="9">
        Distributing or installing any viruses, worms, bugs, Trojan horses or other code, files or programs designed to, or capable of, disrupting, damaging or limiting the functionality of any software or hardware.
      </li>
    </ol>
    <p>g. All .bank domains must be used to serve the needs of the global banking community. In using Your .bank domain, You may not use Your domain for any purposes prohibited by the laws of the jurisdiction(s) in which You do business or any other applicable law. The use of Your .bank domain name must respect all the provisions in this Clause 12. In addition, You may not use Your .bank domain for the following types of activity or purposes:</p>
    <ol type="i">
      <li value="1">
        Engaging in any activity purpose prohibited by the bank's charter or license; or
      </li>

      <li value="2">
        Promoting or engaging in pornography; or
      </li>

      <li value="3">
        Interfering with the operation of .bank gTLD or services offered by the .bank Registry Operator; or
      </li>

      <li value="4">
        Disseminating content that contains false or deceptive language, or unsubstantiated or comparative claims, regarding the .bank Registry Operator; or
      </li>

      <li value="5">
        Licensing your domain to any third party during the period of Your registration.
      </li>

      <li value="6">
        In addition, You acknowledge and agree that proxy registrations are prohibited for all gTLDs operated by the .bank Registry Operator.
      </li>
    </ol>

    <h3>13. DISPUTE RESOLUTION</h3>
    <p>
      a. Any disputes regarding registration or use of your domain name will be subject to the applicable domain name dispute resolution Policy and the Dispute Policy procedures established by the relevant Registry and/or mandated by ICANN, including but not limited to (i) <a href="http://www.icann.org/en/help/dndr/udrp">
        ICANN's Uniform Domain-Name Dispute Resolution Policy
      </a> (&quot;<b>UDRP</b>&quot;); and (ii) <a href="http://newgtlds.icann.org/en/applicants/urs">
        ICANN's Uniform Rapid Suspension Policy
      </a> (&quot;<b>URS</b>&quot;) in those TLDs where the application of these policies is mandatory.
    </p>
    <p>b. Notwithstanding anything in this Agreement to the contrary, the Registry Operator for each COREhub Supported TLD (referred to in <b>Appendix 1</b>) is and shall be an intended third party beneficiary of this Agreement. As such, the parties to this Agreement acknowledge and agree that the third party beneficiary rights of each Registry Operator have vested and that the Registry Operators have relied on their third party beneficiary rights under this Agreement in agreeing to COREhub being a registrar of the TLD they operate. Additionally, the third party beneficiary rights of the Registry Operators shall survive any termination or expiration of this Agreement.</p>

    <h3>14.RESERVATION OF RIGHT TO DENY, SUSPEND, CANCEL AND TRANSFER YOUR DOMAIN NAME</h3>
    <p>a. Without prejudice to Our rights of termination, We expressly reserve the right, in our sole discretion, to deny, suspend, cancel and/or transfer Your domain name registration if:</p>
    <ol type="i">
      <li value="1">
        You willfully provide inaccurate or unreliable Contact Information, or intentionally or negligently fail to promptly update it;
      </li>

      <li value="2">
        You fail to follow the policies of use mandated by ICANN and/or the relevant Registry Policies;
      </li>

      <li value="3">
        You engage in distributing malware, abusively operating botnets, phishing, piracy, trademark or copyright infringement, fraudulent or deceptive practices, counterfeiting or otherwise engaging in activity contrary to applicable law;
      </li>

      <li value="4">
        You fail to respond for over fifteen (15) days to inquiries by Reseller and/or Us concerning the accuracy of Contact Information associated with your domain name or another incidence associated to your domain name;
      </li>

      <li value="5">
        You or third parties reasonably appear to be engaging in illegal activity in the registration or use of the domain name;
      </li>

      <li value="6">
        You use the domain name to send unsolicited commercial advertisements in contradiction to either applicable laws or customary acceptable usage policies of the Internet;
      </li>

      <li value="7">
        The domain name reasonably appears to be infringing upon or otherwise violate the rights of third parties;
      </li>

      <li value="8">
        You engage in a conduct that may reasonably put Us in breach of any applicable Law, governing authority, Registry Regulation, public policy or third party agreement; and/or
      </li>

      <li value="9">
        Law enforcement, a Court of Justice or another relevant authority requests that We do so.
      </li>
    </ol>
    <p>b. You acknowledge and agree that your domain name registration may be denied, cancelled, suspended and/or transferred pursuant to any ICANN adopted specification or Registry Policy or pursuant to any registrar or registry procedure not inconsistent with an ICANN adopted specification:</p>
    <ol type="i">
      <li value="1">
        to correct mistakes by Us, the Reseller and/or the Registry Operator in registering the name, including but not limited to, the cancellation within forty five (45) days from registration of your domain name when its registration has taken place as a result of a previous erroneous cancellation; or
      </li>

      <li value="2">
        for the resolution of disputes concerning the domain name. For example, We and the Registry Operator reserve the right to put your domain name on hold during resolution of a UDRP or URS dispute.
      </li>
    </ol>
    <p>c. You acknowledge and agree that the relevant Registry Operator also reserves the right to deny, suspend, modify the status, cancel and/or transfer any registration that it deems necessary, in its discretion, (i) to protect the integrity, security, and stability or the Registry; (ii) to comply with all applicable laws, government rules </p>
    <p>d. or requirements, requests of law enforcement, in compliance with any dispute resolution process; (iii) to avoid any liability, civil or criminal, on the part of the Registry Operator as well as its affiliates, subsidiaries, officers, directors, representatives, employees, and stockholders; (iv) for violations of the terms and conditions of this Agreement.</p>

    <h3>15.TERM</h3>
    <p>This Agreement will remain in effect during the term of your domain name registration as selected, recorded and paid for at the time of registration or any renewal thereof. Should the domain name be transferred to another registrar, the terms and conditions of this Agreement shall cease.</p>

    <h3>16. LIMITATION OF LIABILITY</h3>
    <p>a. You accept that, to the extent permitted by Law, We shall not be liable to You for any direct or indirect or consequential loss or damage of any kind (including but not limited to loss of profit, goodwill, business opportunity or anticipated saving) suffered due to:</p>
    <ol type="i">
      <li value="1">
        Any loss of registration of a domain name for whatever reason not due to our negligence or willful misconduct;
      </li>

      <li value="2">
        Interruptions, access delays, system errors, omissions, or failures in our registration system;
      </li>

      <li value="3">
        Non-delivery or misdelivery of data between You and Us; or Us and the Registries;
      </li>

      <li value="4">
        Interruptions or failures of hosting and/or other services not provided by Us, or other events beyond our reasonable control;
      </li>

      <li value="5">
        Change of Registry and/or ICANN Policies;
      </li>

      <li value="6">
        Events produced pursuant to the instructions received from the Registry Operators and/or ICANN;
      </li>

      <li value="7">
        Suspension, cancellation or lose of registration or control -temporarily or definitely- of a domain name registered through COREhub:
      </li>
      <ol type="1">
        <li value="1">
          In accordance with this Agreement; or
        </li>

        <li value="2">
          Connected with a decision of the Registry; or
        </li>

        <li value="3">
          Due to Registry's negligence or Registry's insolvency;
        </li>
      </ol>
      <li value="8">
        Non-renewal of a domain name because You did not successfully renew prior to the expiration of the then current term;
      </li>

      <li value="9">
        Renewal of a domain name when You have not cancelled it in the appropriate timeframe;
      </li>

      <li value="10">
        Any other events beyond our reasonable control, or not due to our negligence or willful misconduct.
      </li>
    </ol>
    <p>b. To the extent permitted by Law, in no event shall Our entire liability for any and all damages, however caused and on any theory of liability, and Your exclusive remedy arising out of this Agreement, exceed the amounts effectively paid by You to Reseller or to Us for the Registration Services pursuant to this Agreement. </p>
    <p>c. You acknowledge that We are not part of any terms or conditions that Reseller may have established in connection with the payment of the domain names. In accord with this, We will not be responsible for any action or omission that Reseller may take in order to obtain complete payment of the amounts due. </p>

    <h3>17. INDEMNITY</h3>
    <p>a. You shall indemnify and hold harmless the Registry Operator, the Reseller and Us (COREhub), and our respective directors, affiliates, officers, directors, agents, partners, employees, attorneys and subcontractors for any loss, liabilities, damages, costs or expenses, including reasonable attorneys' fees, resulting from any third party claim, action, or demand, whether in court or not, arising out of or related to</p>
    <ol type="i">
      <li value="1">
        Your domain name registration and/or use thereof;
      </li>

      <li value="2">
        Your breach or violation of any term, condition, representation or warranty of this Agreement; or
      </li>

      <li value="3">
        Your violation of any rights of others.
      </li>
    </ol>
    <p>b. This indemnification is in addition to any indemnification required under the UDRP or any similar policy.</p>

    <h3>18. NOTICE</h3>
    <p>a. All notices or other communications in connection with this Agreement given by You to Us (COREhub) shall be in writing and sent by registered mail or by email to the following address:</p>
    <div style="margin-left: 10em;">
      COREHUB S.R.L.U.<br>
      Attention: Amadeu Abril i Abril<br>
      C/ Gran Via de Les Corts Catalanes 452 – Atic-2a, 08015 Barcelona, Spain<br>
      <a href="mailto:legal@corehub.net">
        legal@corehub.net
      </a>
    </div>
    <p>b. All notices or other communications in connection with this Agreement given by COREhub to You shall be in writing and sent by registered mail or by email to the address published in the Whois database.</p>

    <h3>19. EFFECTS OF TERMINATION</h3>
    <p>a. In the absence of Extenuating Circumstances (as defined below), Your domain name shall be deleted within forty-five (45) days of the termination of this Agreement. </p>
    <p>b. For the purposes of this Agreement, &quot;<b>Extenuating Circumstances</b>&quot; are: (i) UDRP action, (ii) a valid court order, (iii) failure of Our renewal process (which does not include Your failure to respond), (iv) the domain name is used by a name server that provides DNS service to third parties, (v) You are subject to bankruptcy proceedings, (vi) payment dispute (where You claim to have paid for a renewal, or a discrepancy in the amount paid), (vii) billing dispute, (viii) domain name subject to litigation in a court of competent jurisdiction, or other circumstance as specifically approved by ICANN and/or the relevant Registries. </p>

    <h3>20. APPLICABLE LAW AND JURISDICTION</h3>
    <p>a. To the extent permitted by Law, this Agreement shall be governed by the laws of the Kingdom Spain. </p>
    <p>b. You agree that if there is a dispute in connection with the use of the domain name, the competent Courts will be the Courts of Barcelona, where Our registered domicile is. If this provision shall be held to be illegal, invalid or unenforceable, the competent Courts will be those of Your domicile, as indicated in the Whois database at the time of the submission of the claim.</p>
    <p>&nbsp;</p>
    <p><b>APPENDIX 1 of the Registration Agreement: REGISTRY POLICIES</b></p>
    <p>
      A list of the Supported TLDs, their Registry Operators, and their Policies is available at <a href="http://corehub.net/registrypolicies/">
        http://corehub.net/registrypolicies/
      </a>.
    </p>
  </div>
</template>

<script>
  import {mapMutations, mapActions} from 'vuex'
  import {locales} from '@/app/i18n/locales'

  export default {
    props: {
      clientName: {
        type: String,
        default: undefined
      },
      clientId: {
        type: Number,
        default: undefined
      },
      lang: {
        type: String,
        default: undefined
      }
    },

    data () {
      return {
        loading: false,
        loadedName: ''
      }
    },

    computed: {
      memberName () {
        return this.clientName
          ? this.clientName
          : this.loading
            ? this.$t ('general.pagination.loading')
            : this.loadedName
      }
    },

    watch: {
      lang () {
        if (this.lang) {
          this.setLanguage (this.lang)
        }
      },
      clientId () {
        if (this.clientId) {
          this.loadClientName ()
        }
      }
    },

    created () {
      if (this.lang) {
        this.setLanguage (this.lang)
      }

      if (this.clientId && !this.clientName) {
        this.loadClientName ()
      }
    },

    methods: {
      ...mapMutations ('locale', [
        'setAppLocale'
      ]),

      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadClientName () {
        this.loading = true
        this.fetchData ({
          op: 'transfer/client',
          params: {
            id: this.clientId
          },
          cb: data => {
            this.loadedName = data.string
          },
          cbFinal: () => {
            this.loading = false
          }
        })
      },

      setLanguage (lang) {
        const l = locales.find (i => i === lang) ? lang : 'en-US'
        this.$i18n.locale = l || 'en'
        this.setAppLocale (l || 'en')
      }
    }
  }
</script>

<style scoped>
body,
td
{ font-family: Verdana, LucidaSans, Lucida Sans, Helvetica, Arial, sans-serif;
  font-size: 9pt;
}

h1,h2,h3,h4,h5
{ font-family: Verdana, LucidaSans, Lucida Sans, Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.langtitle
{ line-height: 11pt;
  font-size: 7pt;
  font-weight: bold;
  margin-top: 25pt;
  border-top: 2px solid #FFC100;
}

.langentry
{ line-height: 11pt;
}

.contents
{ margin-left: 40pt;
  margin-right: 60pt;
  margin-top: 15pt;
  margin-bottom: 15pt;
}

.par
{ margin-top: 9pt;
  margin-bottom: 9pt;
  line-height: 11pt;
}

.emp
{ font-weight: bold;
  color: #990000;
}

.gapcell
{ padding-right: 20pt;
}

a:link,
a:active,
a:visited
{ color: #2f75b9
}

a:hover
{ color: #000000;
}
</style>
